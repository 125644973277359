<template>
  <div>
    <div class="router-view-container">
      <h1 class="page_title mb-5">{{ $route.meta.title }}</h1>

      <main class="space-y-10">
        <div class="flex flex-col sm:flex-row sm:space-x-10 space-y-4 sm:space-y-0">
          <div class="rounded-xl sm:mt-4 w-full sm:w-1/3 flex-shrink-0 h-fit">
            <div>
              <h3 class="h3 mb-4">分類</h3>
              <p>將商品加入分類，幫助顧客更容易瀏覽和查找您的商品，您也可以拖曳分類項目，組合成嵌套分類。</p>
            </div>
          </div>
          <div class="box p-4 flex-grow space-y-4">
            <div class="mb-4 flex items-start justify-between">
              <h3 class="h3">分類項目</h3>
              <router-link :to="{ name: 'new-collection' }" class="flex items-center space-x-1">
                <svg-icon icon-class="add" className="success"></svg-icon>
                <span class="font-medium text-success">新增分類</span>
              </router-link>
            </div>
            <hr />
            <section>
              <!-- 選單項目 -->
              <draggable
                v-if="is_API_loaded && lists.length > 0"
                class="draggable1"
                group="list"
                v-model="lists"
                tag="ul"
                :component-data="getComponentData(null)"
                :empty-insert-threshold="emptyInsertThreshold"
                :fallbackOnBody="fallbackOnBody"
                :swapThreshold="swapThreshold"
                :animation="animation"
                :move="onMove"
                :dragClass="dragClass"
                :chosenClass="chosenClass"
                :ghostClass="ghostClass"
                handle=".handle"
                @change="onChange"
                @update="onUpdate"
                @start="onStart"
                @end="onEnd"
                @choose="onChoose"
                @sort="onSort"
              >
                <!-- 第一層 -->
                <li class="item1_wrap" v-for="(item1, index) in newLists" :key="item1.smno">
                  <div class="item1">
                    <div class="flex items-center w-0 flex-auto">
                      <svg-icon class="handle flex-shrink-0" icon-class="drag" className="gray-divide"></svg-icon>
                      <p class="text-limit-1">{{ item1.title }}</p>
                    </div>
                    <div class="flex flex-shrink-0">
                      <router-link :to="{ name: 'edit-collection', params: { id: item1.sptno } }">
                        <vs-button transparent size="small" color="success">編輯</vs-button>
                      </router-link>
                    </div>
                  </div>
                  <draggable
                    class="draggable2"
                    group="list"
                    :list="item1.arr"
                    tag="ul"
                    :component-data="getComponentData(index)"
                    :empty-insert-threshold="emptyInsertThreshold"
                    :fallbackOnBody="fallbackOnBody"
                    :swapThreshold="swapThreshold"
                    :animation="animation"
                    :move="onMove"
                    :dragClass="dragClass"
                    :chosenClass="chosenClass"
                    :ghostClass="ghostClass"
                    handle=".handle"
                    @change="onChange"
                    @update="onUpdate"
                    @start="onStart"
                    @end="onEnd"
                    @choose="onChoose"
                    @sort="onSort"
                  >
                    <!-- 第二層 -->
                    <li class="item2_wrap" v-for="item2 in item1.arr" :key="item2.smno">
                      <div class="item2">
                        <div class="flex items-center w-0 flex-auto">
                          <svg-icon class="handle flex-shrink-0" icon-class="drag" className="gray-divide"></svg-icon>
                          <p class="text-limit-1">{{ item2.title }}</p>
                        </div>

                        <div class="flex flex-shrink-0">
                          <router-link :to="{ name: 'edit-collection', params: { id: item2.sptno } }">
                            <vs-button transparent size="small" color="success">編輯</vs-button>
                          </router-link>
                        </div>
                      </div>

                      <draggable
                        class="draggable3"
                        group="list"
                        :list="item2.arr"
                        tag="ul"
                        :component-data="getComponentData(index)"
                        :empty-insert-threshold="emptyInsertThreshold"
                        :fallbackOnBody="fallbackOnBody"
                        :swapThreshold="swapThreshold"
                        :animation="animation"
                        :move="onMove"
                        :dragClass="dragClass"
                        :chosenClass="chosenClass"
                        :ghostClass="ghostClass"
                        handle=".handle"
                        @change="onChange"
                        @update="onUpdate"
                        @start="onStart"
                        @end="onEnd"
                        @choose="onChoose"
                        @sort="onSort"
                      >
                        <!-- 第三層 -->
                        <li class="item3_wrap" v-for="item3 in item2.arr" :key="item3.smno">
                          <div class="item3">
                            <div class="flex items-center w-0 flex-auto">
                              <svg-icon class="handle flex-shrink-0" icon-class="drag" className="gray-divide"></svg-icon>
                              <p class="text-limit-1">{{ item3.title }}</p>
                            </div>
                            <div class="flex flex-shrink-0">
                              <router-link :to="{ name: 'edit-collection', params: { id: item3.sptno } }">
                                <vs-button transparent size="small" color="success">編輯</vs-button>
                              </router-link>
                            </div>
                          </div>
                        </li>
                      </draggable>
                    </li>
                  </draggable>
                </li>
              </draggable>

              <div v-if="!is_API_loaded" class="space-y-2 mb-2">
                <skeleton v-for="(skeleton, index) in 5" :key="index" />
              </div>

              <p v-if="lists.length === 0 && is_API_loaded" class="text-center">尚無任何分類</p>
            </section>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: 'collections',
  components: {
    draggable,
  },
  data() {
    return {
      is_API_loaded: false,
      lists: [],
      animation: 150,
      forceFallback: false,
      swapThreshold: 0.3,
      fallbackOnBody: false,
      emptyInsertThreshold: 10,
      dragClass: 'dragClass', // 拖動時的懸浮樣式
      chosenClass: 'chosenClass', // 被選中的區塊樣式
      ghostClass: 'ghostClass',
      delay: 0,
    }
  },
  computed: {
    newLists() {
      let newAry = []

      this.lists.forEach((item) => {
        item.layers = 1
        if (item.arr.length > 0) {
          item.layers = 2

          item.arr.forEach((item2) => {
            if (item2.arr.length > 0) {
              item.layers = 3
            }
          })
        }
        newAry.push(item)
      })
      return newAry
    },
  },
  created() {
    // 讀取選單
    this.getLists()
  },
  methods: {
    onMove(evt, originalEvent) {
      // 放置的位置 draggable1 / draggable2 / draggable3
      let draggedPlace = evt.to.classList[0]
      // console.log('放置的位置', draggedPlace)

      let from_layers = evt.draggedContext.element.layers
      // console.log('from layer', from_layers)

      let to_layers = 0
      if (evt.to.__vue__.componentData.props.list !== undefined) {
        to_layers = evt.to.__vue__.componentData.props.list.layers
        // console.log('to layer', to_layers)
      }

      let total_layers = from_layers + to_layers
      // console.log('total_layers', total_layers)

      this.allowChange

      // 兩層選單可以放到第二層選單中
      if (from_layers <= 2 && draggedPlace === 'draggable2') {
        return true
        // 大於等於兩層的選單，不能放到第三層選單中
      } else if (from_layers >= 2 && draggedPlace === 'draggable3') {
        return false
        // 大於等於三層的選單，不能放到第二層
      } else if (from_layers >= 3 && draggedPlace === 'draggable2') {
        return false
      } else if (total_layers >= 5) {
        return false
      } else if (draggedPlace === 'draggable1') {
        return true
      }
    },
    getComponentData(index) {
      return {
        props: {
          list: this.newLists[index],
        },
      }
    },
    // 當元素被移動時觸發
    onChange(evt) {
      for (let item in evt) {
        if (item === 'added' || item === 'moved') {
          // 儲存選單
          this.saveArrange()
        }
      }
    },
    onChoose(evt) {
      // console.log('on onChoose', evt)
    },
    onUpdate(evt) {
      // console.log(evt)
    },
    onStart(evt) {
      // console.log(evt)
    },
    onSort(evt) {
      // console.log(evt)
    },
    onEnd(evt) {},
    // 3.1.3.1 讀取分類列表
    getLists() {
      this.$axios({
        url: 'front//store/type/getTypeList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.lists = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 3.1.3.6 分類資料-修改排序
    saveArrange() {
      this.$axios({
        url: 'front/store/type/uRankTypeData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          // 轉成json 並且只保留sptno跟arr
          sptno: JSON.stringify(this.lists, ['sptno', 'arr']),
        },
      }).then((res) => {
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.getLists()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  @apply bg-white rounded-xl;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

.draggable1 {
  @apply space-y-2 pb-2;
}

.item1,
.item2,
.item3 {
  @apply h-10 rounded-xl flex items-center justify-between border px-2;
}

.item2,
.item3 {
  border-top: none;
}

.draggable2 {
  // @apply space-y-2;
}

.item2_wrap,
.item3_wrap {
  // @apply space-y-2;
  margin-left: 30px;
  // border-radius: 12px;
}

.item1_wrap {
  // @apply space-y-2;
}

.draggable3 {
  // @apply space-y-2;
}

.dragClass {
  @apply shadow-lg overflow-hidden border-success bg-white opacity-100 rounded-xl;
}

.chosenClass {
  @apply border border-success rounded-xl overflow-hidden;
}

// .ghostClass {
//   @apply bg-gray-background rounded-xl;
// }

.handle {
  cursor: grab;
  margin: 0px 8px 0px 0px;
}
</style>
